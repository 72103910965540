import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
    selector: 'app-input-item',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class InputItemComponent implements OnInit, AfterViewInit {

    @Output() valueChange = new EventEmitter<string>();
    @Output() inputChange = new EventEmitter<string>();
    @Output() focused = new EventEmitter<string>();

    @Input() value: string;
    @Input() label: string;
    @Input() name: string;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() pattern: string;
    @Input() inputMode: string;
    @Input() id: string;
    @Input() placeholder = '';
    @Input() mask: string;
    @Input() dropSpecialCharacters: boolean;
    @Input() validCheck = false;
    @Input() code = false;
    @Input() authForm = false;
    _type = 'text';
    @Input() set type(v) {
        if (v === 'password') {
            this.isPassword = true;
        } else {
            this.isPassword = false;
            this._type = v;
        }
        this.updateInput();
    }

    get type() {
        return this._type;
    }

    @Input() spellcheck = false;
    @Input() autocapitalize = 'off';
    @Input() required = false;
    @Input() readonly = false;
    @Input() disabled = false;
    @Input() autofocus = false;
    @Input() autocomplete = 'off';

    @ViewChild('field') field;

    public isPassword = false;
    public email = false;
    public isTypePhone = false;
    phoneMask = true;
    @Input() customPatterns: any = {
        c: {pattern: new RegExp('\[0-6,9\]')},
        0: {pattern: new RegExp('\[0-9\]')}
    };

    ngOnInit() {
        this.phoneMask = this.value?.length <= 0;
        if (this.type === 'email') {
            this.email = true;
        }

        if (this.type === 'password') {
            this.isPassword = true;
        }

        if (this.type === 'tel') {
            this.isTypePhone = true;
        }
    }

    ngAfterViewInit() {
        this.updateInput();
    }

    private updateInput() {
        this.phoneMask = this.value?.length <= 0;
        if (this.field && this.inputMode) {
            this.field.nativeElement.setAttribute('inputmode', this.inputMode);
        }
    }

    changeValue(value: string, changeType: string) {
        this[changeType].emit(value);
        this.phoneMask = value.length <= 0;
    }
}
