import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CartGuard} from './core/guards/cart.guard';
import {AuthGuard} from './core/guards/auth.guard';

const routes: Routes = [
    {
        path: 'menu',
        loadChildren: () => import('./pages/menu/page.module').then(m => m.MenuPageModule),
        data: {
            title: 'Меню',
            icon: 'menu',
            menu: true
        }
    },
    {
        path: 'location',
        loadChildren: () => import('./pages/location/page.module').then(m => m.LocationPageModule),
    },
    {
        path: 'promos',
        loadChildren: () => import('./pages/promos/page.module').then(m => m.PromosPageModule),
        data: {
            title: 'Акции',
            icon: 'discount',
            menu: true
        }
    },
    {
        path: 'delivery',
        loadChildren: () => import('./pages/delivery/page.module').then(m => m.DeliveryPageModule),
        data: {
            title: 'Доставка',
            icon: 'delivery',
            menu: true
        }
    },
    {
        path: 'branches',
        loadChildren: () => import('./pages/branches/page.module').then(m => m.BranchesPageModule),
        data: {
            title: 'Рестораны',
            icon: 'contacts',
            menu: true
        }
    },
    {
        path: 'branches/:id',
        loadChildren: () => import('./pages/branches/branch/page.module').then(m => m.BranchPageModule),
    },
    {
        path: 'about_us',
        loadChildren: () => import('./pages/about_us/page.module').then(m => m.AboutUsPageModule),
        data: {
            title: 'О нас',
            icon: 'about',
            menu: true
        }
    },
    {
        path: 'auth/phone',
        loadChildren: () => import('./pages/auth/phone/page.module').then(m => m.AuthPhonePageModule),
        data: {
            title: 'Авторизация',
            icon: 'registr',
            menu: true
        }
    },
    {
        path: 'about_us/confidence',
        loadChildren: () => import('./pages/about_us/confidence/page.module').then(m => m.AboutUsConfidencePageModule),
    },
    {
        path: 'profile/main',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/main/page.module').then(m => m.ProfileMainPageModule),
        data: {
            title: 'Профиль',
            icon: 'registr',
            menu: true
        }
    },
    {
        path: 'auth/phone',
        loadChildren: () => import('./pages/auth/phone/page.module').then(m => m.AuthPhonePageModule),
    },
    {
        path: 'auth/code',
        loadChildren: () => import('./pages/auth/code/page.module').then(m => m.CodePhonePageModule),
    },
    {
        path: 'auth/success',
        loadChildren: () => import('./pages/auth/success/page.module').then(m => m.SuccessPageModule),
    },
    {
        path: 'auth/auth-error',
        loadChildren: () => import('./pages/auth/auth-error/page.module').then(m => m.AuthErrorPageModule),
    },
    {
        path: 'auth/auth-form',
        loadChildren: () => import('./pages/auth/auth-form/page.module').then(m => m.AuthFormPageModule),
    },
    {
        path: 'profile/edit',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/edit/page.module').then(m => m.ProfileEditPageModule),
    },
    {
        path: 'profile/addresses',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/addresses/page.module').then(m => m.ProfileAddressesPageModule),
    },
    {
        path: 'profile/address_edit',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/address_edit/page.module').then(m => m.ProfileEditAddressPageModule),
    },
    {
        path: 'profile/notifications',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/notifications/page.module').then(m => m.ProfileNotificationsPageModule),
    },
    {
        path: 'profile/orders',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/orders/page.module').then(m => m.ProfileOrdersPageModule),
    },
    {
        path: 'profile/orders/:orderId',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/order/page.module').then(m => m.ProfileOrderPageModule),
    },
    {
        path: 'profile/commentaries',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/commentaries/page.module').then(m => m.ProfileCommentariesPageModule),
    },
    {
        path: 'profile/comment',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/profile/comment/page.module').then(m => m.ProfileCommentPageModule),
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/page.module').then(m => m.PrivacyPolicyPageModule),
    },
    {
        path: 'category',
        loadChildren: () => import('./pages/category/page.module').then(m => m.CategoryPageModule),
    },
    {
        path: 'category/:categoryId',
        loadChildren: () => import('./pages/category/page.module').then(m => m.CategoryPageModule),
    },
    {
        path: 'category/:categoryId/:subCategoryId',
        loadChildren: () => import('./pages/category/page.module').then(m => m.CategoryPageModule),
    },
    {
        path: 'ordering',
        canActivateChild: [CartGuard, AuthGuard],
        loadChildren: () => import('./pages/ordering/main/page.module').then(m => m.OrderingMainModule),
    },
    {
        path: 'ordering/payment',
        canActivateChild: [CartGuard, AuthGuard],
        loadChildren: () => import('./pages/ordering/payment/page.module').then(m => m.OrderingPaymentModule),
    },
    {
        path: 'ordering/order-check',
        canActivateChild: [CartGuard, AuthGuard],
        loadChildren: () => import('./pages/ordering/order-check/page.module').then(m => m.OrderingOrderCheckModule),
    },
    {
        path: 'ordering/payment-3ds',
        canActivateChild: [CartGuard, AuthGuard],
        loadChildren: () => import('./pages/ordering/payment-3ds/page.module').then(m => m.OrderingPayment3dsModule),
    },
    {
        path: 'ordering/finish',
        canActivateChild: [CartGuard, AuthGuard],
        loadChildren: () => import('./pages/ordering/finish/page.module').then(m => m.OrderingFinishModule),
    },
    {
        path: 'ordering/finish/:orderId',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/ordering/finish/page.module').then(m => m.OrderingFinishModule),
    },
    {
        path: 'payment-rules',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/payment-rules-page/page.module').then(m => m.PaymentRulesModule),
    },
    {
        path: 'track',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/track/page.module').then(m => m.TrackPageModule),
    },
    {
        path: 'track/:orderId/:iikoId',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/track/page.module').then(m => m.TrackPageModule),
    },
    {
        path: 'survey',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/survey/page.module').then(m => m.SurveyPageModule),
        data: {
            title: 'Опросы',
            icon: 'survey',
            menu: true
        }
    },
    {
        path: 'cart',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/cart/page.module').then(m => m.CartPageModule),
    },
    {
        path: 'update',
        loadChildren: () => import('./pages/update/page.module').then(m => m.UpdatePageModule),
    },
    {
        path: 'city-selection',
        loadChildren: () => import('./pages/city-selection/page.module').then(m => m.CitySelectionModule),
    },
    {
        path: 'search-page',
        loadChildren: () => import('./pages/search-page/page.module').then(m => m.SearchPageModule),
        data: {
            name: 'SearchPage',
        }
    },
    {
        path: 'favorite',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/favorite/page.module').then(m => m.FavoritePageModule),
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
