import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CartService} from '../../models/cart.service';
import {AppService} from '../../services/app.service';
import {Subject} from 'rxjs';
import {MainModal} from '../../core/main.Modal';
import {ModalService} from '../../services/modal.service';
import {GestureController} from '@ionic/angular';
import Scrollbar from 'smooth-scrollbar';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';


@Component({
    selector: 'bottom-modal',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
})

export class BottomModalComponent extends MainModal implements OnInit {
    BACK_DROP_OPACITY = 0.3;
    @ViewChild('scrollContainer', {static: false})
    scrollContainer;
    private scrollBar: Scrollbar;

    @Input() time: string;
    @Input() date: string;
    @Input() timeStart: string;
    @Input() todayTimeStart: string;
    @Input() timeEnd: string;
    @Input() timeDelay: string;
    @Input() mode: 'delivery' | 'pickup';
    @Input() tomorrow = false;
    @Input() currentTime = '';

    cartService: CartService;

    selectedDate: {
        labelDate: string;
        weekday: string;
        date: string;
        timeStart?: string;
        timeEnd?: string;
    };
    // количество выводимых дней
    dateRange = 2;

    days = [];

    updateTimePicker: Subject<void> = new Subject<void>();
    @Output() setTimeEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        cartService: CartService,
    ) {
        super(appService, modalService, gestureCtrl);
        this.cartService = cartService;
    }

    ngOnInit() {
        // если с бека приходят days - устанавливаем dateRange из days
        if (this.cartService.cart.options?.timePicker?.days) {
            this.dateRange = this.cartService.cart.options?.timePicker?.days;
        }
        this.createDays();
        this.scrollToActualDay();
        setTimeout(() => {
            this.createVirtualScroll(this.scrollContainer.nativeElement);
        });
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll.bind(this));
        this.onScroll({offset: {x: 0, y: 0}});
    }

    onScroll(e: any) {
        this.modalService.scrollModal(e.offset.y);
    }

    createDays() {
        const today = new Date();
        const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
        const weekdaysMapping = {
            'Вс': 'sunday',
            'Пн': 'monday',
            'Вт': 'tuesday',
            'Ср': 'wednesday',
            'Чт': 'thursday',
            'Пт': 'friday',
            'Сб': 'saturday',
        };

        const currentTimeMins = today.getHours() * 60 + today.getMinutes(); // Текущее время в минутах

        for (let i = 0; i < this.dateRange; i++) {
            const m = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'нояб', 'дек'];
            const labelDate = today.toLocaleString('ru', { day: 'numeric' }) + ' ' + m[today.getMonth()];
            const weekday = days[today.getDay()];
            const weekdayKey = weekdaysMapping[weekday];
            const dayTimeStart = this.cartService.cart.options?.timePicker?.timeStart[weekdayKey];
            const dayTimeEnd = this.cartService.cart.options?.timePicker?.timeEnd[weekdayKey];

            // Преобразуем время конца дня в минуты
            const dayTimeEndMins = this.timeStringToMinutes(dayTimeEnd);

            // Учитываем текущий день
            if (i === 0) {
                const minAvailableTime = currentTimeMins + Number(this.timeDelay); // Минимально доступное время
                if (minAvailableTime >= dayTimeEndMins) {
                    // Если минимальное доступное время превышает конец рабочего времени, пропускаем сегодняшний день
                    today.setDate(today.getDate() + 1);
                    continue;
                }
            }

            this.days.push({
                labelDate,
                weekday,
                date: today.toLocaleString('ru', { day: 'numeric', month: 'numeric', year: 'numeric' }),
                timeStart: dayTimeStart,
                timeEnd: dayTimeEnd,
            });

            today.setDate(today.getDate() + 1);
        }

        if (this.date) {
            // Если дата уже была выбрана, то активна (выбрана) эта дата
            this.days.forEach((item, index) => {
                if (item.date === this.date) {
                    this.selectedDate = this.days[index];
                    this.timeStart = this.selectedDate.timeStart;
                    this.timeEnd = this.selectedDate.timeEnd;
                }
            });
        } else {
            // По умолчанию выбран первый день
            if (this.days.length > 0) {
                this.selectedDate = this.days[0];
                this.chooseDate(0);
            }
        }
    }

    scrollToActualDay() {
        // скролл к актуальному дню
        setTimeout(() => {
            const el: HTMLElement = document.querySelector('.day-item.active-day');
            el.scrollIntoView({
                behavior: this.appService.isIos() ? 'auto' : 'smooth',
                inline: 'center',
                block: 'center'
            });
        }, 500);
    }

    getTimeFromMins(mins) {
        const hours = Math.trunc(mins / 60);
        const minutes = mins % 60;
        return hours + ':' + (Number(minutes) < 10 ? ('0' + minutes) : minutes);
    }

    chooseDate(index: number) {
        setTimeout(() => {
            const selectedDay = this.days[index];

            const now = new Date();
            const todayDate = now.toLocaleString('ru',
                {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            // console.log('todayDate', todayDate);
            // console.log('selectedDay', selectedDay);
            // console.log('isToday', todayDate === selectedDay.date);

            const isToday = todayDate === selectedDay.date;

            // Применяем время из выбранного дня
            if (index === 0 && isToday) { // Проверяем, что выбран текущий день
                const currentTimeMins = now.getHours() * 60 + now.getMinutes(); // Текущее время в минутах
                const minAvailableTime = this.getTimeFromMins(currentTimeMins); // Текущее время
                // console.log('Текущий день. Текущее время: ', minAvailableTime);

                this.timeStart = minAvailableTime; // Устанавливаем timeStart как минимально возможное время
            } else {
                this.timeStart = selectedDay.timeStart; // Для остальных дней используем указанное время начала
            }

            this.timeEnd = selectedDay.timeEnd;

            const time = this.getTimeFromMins(Number(this.timeStringToMinutes(this.timeStart)) + Number(this.timeDelay));
            // Устанавливается время минимального возможного выбора
            // console.log('устанавливается время минимального возможного выбора: ', index === 0 && isToday ? this.roundTime(time) : time);
            this.time = index === 0 && isToday ? this.roundTime(time) : time;

        }, 200);

        setTimeout(() => {
            // Обновляется timePicker
            this.updateTimePicker.next();
        }, 400);

        this.selectedDate = this.days[index];
    }

    roundTime(time: string) {
        // округление времени до 30 в большую сторону
        let hours = Number(time.slice(time[0] === '0' ? 1 : 0, 2));
        let minutes = Number(time.slice(time[3] === '0' ? 4 : 3, 5));
        if (minutes > 30) {
            hours = hours < 24 ? hours + 1 : 0;
            minutes = 0;
        } else {
            minutes = 30;
        }
        return (Number(hours) < 10 ? ('0' + hours) : hours) + ':' + (Number(minutes) < 10 ? ('0' + minutes) : minutes);
    }

    timeStringToMinutes(timeString: string) {
        const [h, m] = timeString.split(':');
        return Number(h) * 60 + Number(m);
    }

    setTime() {
        this.setTimeEvent.emit({
            action: 'setTime',
            mode: this.mode,
            time: this.time,
            date: this.selectedDate
        });
        this.modalService.close();
    }

    get title() {
        return this.mode === 'pickup' ?
            this.cartService.cart.options.timePicker.pickupTitle :
            this.cartService.cart.options.timePicker.deliveryTitle;
    }

    get text() {
        return this.mode === 'pickup' ?
            this.cartService.cart.options.timePicker.pickupText :
            this.cartService.cart.options.timePicker.deliveryText;
    }
}
